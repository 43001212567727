import React from "react"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import find from '@patomation/find'

import Layout from '../components/Layout'
import renderAst from './_renderAst.js'
import { Image, ImageGrid } from '@patomation/react-ui-components'

const Profile = ({
  data
}) => data ?
  <Layout
  {...find('frontmatter', data)}
  title={find('name', data)}
  description={find('position', data)}>
    <section>
    <Image
      square
      src={find('image', data)}
      style={{
        width: '50%',
        paddingBottom: '50%',
        display: 'inline-block',
        float: 'left',
        marginRight: '1rem',
        marginBottom: '1rem',
      }}/>

    { renderAst( find('htmlAst', data) ) }

    <ImageGrid images={find('collection', data)} />
    </section>
  </Layout>
: null

Profile.propTypes = {
  data: PropTypes.object
}

export default Profile;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      htmlAst
      excerpt(pruneLength: 160)
      frontmatter {
        name
        position
        hero_image
        image
        collection
      }
    }
  }
`
